<template>
  <div class="page-root">
    <page-title color="green">
      <template v-slot:right>結果一覧</template>
    </page-title>

    <div class="heads-up">
      <p class="text">
        本ページは、ブックマークや印刷をして大切に保管し、
        <br />
        診察時に医師にお見せください。
      </p>
    </div>

    <diagnosis-result :is-adhd="isADHD" />
    <answer-result
      :questions="aQuestions"
      :answers="aAnswers"
      color="green"
      title="ADHDセルフチェックの結果"
    />
    <answer-result
      :questions="bQuestions"
      :answers="bAnswers"
      color="blue"
      title="受診前のADHDセルフ問診の結果"
    />

    <div class="top-link">
      <link-button link="AdhdHome">
        セルフチェックTOPへ
      </link-button>
    </div>
  </div>
</template>

<script>
import rootStore from "@/store";
import { partAQuestions, partBQuestions } from "@/data/adhd";
import { urlMixin } from "@/mixin/adhd";
import DiagnosisResult from "@/components/adhd/DiagnosisResult";
import PageTitle from "@/components/adhd/PageTitle";
import AnswerResult from "@/components/adhd/AnswerResult";
import LinkButton from "@/components/adhd/LinkButton";
const state = rootStore.state;
export default {
  name: "AdhdAnswerResult",
  head: {
    title: {
      inner: "かんたんADHDセルフチェック"
    }
  },
  data: function() {
    return {
      aQuestions: partAQuestions(),
      bQuestions: partBQuestions(),
      aAnswers: undefined,
      bAnswers: undefined
    };
  },
  components: { PageTitle, DiagnosisResult, AnswerResult, LinkButton },
  mixins: [urlMixin],
  created() {
    if (typeof this.$route.query.answers === "string") {
      const answers = JSON.parse(decodeURIComponent(this.$route.query.answers));
      this.aAnswers = answers.answer.data.a
        ? answers.answer.data.a
        : Array.from(Array(this.aQuestions.length));
      this.bAnswers = answers.answer.data.b
        ? answers.answer.data.b
        : Array.from(Array(this.bQuestions.length));
    } else {
      this.aAnswers = state.adhd.screeningAnswersApart;
      this.bAnswers = state.adhd.screeningAnswersBpart;
    }
  },
  computed: {
    isADHD() {
      const point = this.aAnswers.reduce(
        (sum, element, idx) => sum + this.aQuestions[idx].answerPoints[element],
        0
      );
      return point >= 4;
    }
  },
  methods: {
    async onNext() {
      await this.$router.push("/adhd/second_screening");
    }
  }
};
</script>
<style scoped>
@import "../../assets/style/adhd.css";

.heads-up {
  background-color: #fff8f7;
}
.heads-up .text {
  padding: 12px 16px;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  color: #fa7864;
}
.top-link {
  margin: 48px 16px 16px 16px;
}
</style>
